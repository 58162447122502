<template>
  <div v-if="formConfig" class="report-editor view pt-1">
    <div class="row pl-1 bg-extra-light">
      <div class="col-lg-8 report-column">
        <div class="sticky-top bg-extra-light px-3 mx-n3 pb-1 pt-2 d-flex justify-content-between align-items-center">
          <router-link-back :to="{ name: 'Reports', params: { reportConfigId } }" v-if="reportConfigId">
            {{ formConfig.name ?? $t('report_editor.unnamed_form') }}
          </router-link-back>
          <router-link-back :to="{ name: 'Report Configs' }" v-else>
            {{ $t('navigation.modules.reports') }}
          </router-link-back>
        </div>
        <h3 class="text-uppercase mb-4 mt-3">{{ formConfig.name ?? $t('report_editor.unnamed_form') }}</h3>
        <div class="alert alert-warning" role="alert" v-if="reportHandlersCount > 0">
          {{ $tc('report_editor.report_handler_warning', reportHandlersCount, { count: reportHandlersCount }) }}
        </div>
        <form-configured-content
            ref="form_content"
            :contents="formConfig.fields"
            :content-title="$t('report_editor.grab_or_click')"
            :value="values"
            mode="edit"
            enable-drag-n-drop
            @drag-item-start="draggingElement = $event"
            @click="editContent = $event"
        >

          <template #beforeContent="{content, contentIndex, dragOver, parentId}">
            <div class="dropzone">
              <div class="dropzone-inner before"
                   @drop.stop="onDrop(draggingElement|| preparedContent, dragOver, parentId, false)">
                {{
                  $t('report_editor.dropzone_before',
                      { content_name: content.options.label || content.options.text || content.type })
                }}
              </div>
            </div>
          </template>

          <template #afterContent="{content, contentIndex, dragOver, parentId}">
            <div class="dropzone">
              <div class="dropzone-inner after"
                   @drop.stop="onDrop(draggingElement|| preparedContent, dragOver, parentId, true)">
                {{
                  $t('report_editor.dropzone_after',
                      { content_name: content.options.label || content.options.text || content.type })
                }}
              </div>
            </div>
          </template>

        </form-configured-content>
        <general-modal
            v-if="editContent"
            :close-on-backdrop="false"
            @close="editContent = null"
        >
          <form-content-composer
              :value="editContent"
              :content-keys="contentKeys"
              :input-keys="inputKeys"
              edit-mode
              @input="onEditContent"
              @save="onSaveContent"
          />
          <template #footer>
            <button
                type="button"
                class="btn btn-danger"
                @click="removeContent(editContent?.id, formConfig.fields); editContent = null"
            >
              {{ $t('report_editor.delete_content') }}
            </button>
          </template>
        </general-modal>
      </div>
      <div class="col-lg-4 tool-box-column">
        <form-radio class="tag text-uppercase sticky-top bg-white" :options="toolboxTabs" v-model="toolboxTab"/>
        <form v-if="formConfig" :class="{'d-none': toolboxTab !== 'form_settings'}" @submit.prevent="saveForm">
          <h5 class="text-uppercase">{{ $t('report_editor.form_settings_header') }}</h5>
          <form-input
              :label="$t('report_editor.report_name_label')"
              required
              v-model.trim="formConfig.name"
          />

          <ps-accordion-collapse class="mb-2 bg-white">
            <template #header="{active}">
              <div class="p-2 d-flex align-items-center justify-content-between">
                {{ $t('report_editor.states_label') }}
                <i class="fas fa-chevron-left" :class="{active}" aria-hidden="true"></i>
              </div>
            </template>
            <template #content>
              <div class="p-2">
                <div v-for="(option, idx) in formConfig.states"
                     :key="option.id"
                     class="form-row border-light border-bottom">
                  <div class="col-12 text-right small pt-1">
                    <button type="button"
                            class="btn btn-sm btn-outline-danger mb-n3"
                            @click="removeState(idx)"
                    ><i class="fas fa-trash" aria-hidden="true"></i></button>
                  </div>
                  <div class="col-6">
                    <form-input
                        :label="$t('form_content_composer.options_input.options.value_label')"
                        :value="option.value"
                        @input="onInput(['states', idx, 'value'], $event)"
                    />
                  </div>
                  <div class="col-6">
                    <form-input
                        :label="$t('form_content_composer.options_input.options.label_label')"
                        :value="option.label"
                        @input="onInput(['states', idx, 'label'], $event)"
                    />
                  </div>
                </div>

                <button type="button"
                        class="btn btn-primary btn-block"
                        @click="onInput(['states', formConfig.states?.length || 0], getEmptyOption())"
                >
                  {{ $t('form_content_composer.options_input.options.add_button') }}
                </button>
              </div>
            </template>
          </ps-accordion-collapse>

          <ps-accordion-collapse class="my-2 bg-white">
            <template #header="{active}">
              <div class="p-2 d-flex align-items-center justify-content-between">
                {{ $t('report_editor.list_columns') }}
                <i class="fas fa-chevron-left" :class="{active}" aria-hidden="true"></i>
              </div>
            </template>
            <template #content>
              <form-list-column-composer class="p-2" v-model="formConfig.fields"/>
            </template>
          </ps-accordion-collapse>

          <div class="bg-white p-2 my-2">
            <div class="mb-2">
              {{ $t('report_editor.suggestion_file_label') }}
            </div>

            <multi-file-upload
                :button-label-html-first-item="$t('report_editor.suggestions')"
                :allowed-file-extensions="['.db', '.sqlite']"
                :allowed-mime-types="[]"
                :max-file-count="1"
                :value="[formConfig.suggestion_file].filter(el => !!el)"
                @input="uploadSuggestions"
            />

          </div>

          <div class="form-row mt-5">
            <div class="col-6">
              <button-confirmation
                  :form-mode="false"
                  :disabled="saving"
                  :modal-title="$t('report_editor.delete_modal.title')"
                  :modal-body="$t('report_editor.delete_modal.body', {name: this.formConfig.name})"
                  :modal-yes="$t('report_editor.delete_modal.yes')"
                  :modal-no="$t('report_editor.delete_modal.no')"
                  @click="commitDelete"
              >
                {{ $t('report_editor.delete') }}
              </button-confirmation>
            </div>
            <div class="col-6 text-right">
              <button type="submit" class="btn btn-secondary" :disabled="saving">
                {{ $t('report_editor.save') }}
              </button>
            </div>
          </div>
        </form>
        <div :class="{'d-none': toolboxTab !== 'create_content'}">
          <h5 class="text-uppercase">{{ $t('report_editor.create_content_header') }}</h5>
          <form-content-composer :value="preparedContent" :content-keys="contentKeys" :input-keys="inputKeys" @input="onNewContent"/>
        </div>
      </div>
    </div>
    <dirty-modal
        ref="dirty"
        :entity="formConfig"
        :title="$t('report_editor.dirty.title')"
        :body="$t('report_editor.dirty.body')"
        :save-label="$t('report_editor.dirty.save')"
        :dont-save-label="$t('report_editor.dirty.discard')"
        @save="saveForm"
    />
  </div>
  <loading-screen v-else/>
</template>

<script>
import _cloneDeep from 'lodash/cloneDeep'
import _set from 'lodash/set'
import { v1 } from 'uuid'

import { mapActions, mapGetters } from 'vuex'

import FormConfiguredContent from '@pixelstein/ps-form/components/PsFormConfiguredContent'
import FormInput from '@pixelstein/ps-form/components/PsFormInput'
import FormRadio from '@pixelstein/ps-form/components/PsFormRadio'
import PsAccordionCollapse from 'pixelstein-vue-app-package/src/vue2/PsAccordion/PsAccordionCollapse'

import MultiFileUpload from '@/components/Reports/MultiFileUpload.vue'
import FormContentComposer from '@/components/Reports/FormContentComposer'
import FormListColumnComposer from '@/components/Reports/FormListColumnComposer'

import GeneralModal from 'pixelstein-vue-app-package/src/vue2/PsModal/PsModalGeneralModal'
import ButtonConfirmation from 'pixelstein-vue-app-package/src/vue2/PsModal/PsModalButtonConfirmationModal'
import DirtyModal from 'pixelstein-vue-app-package/src/vue2/PsModal/PsModalDirtyModal'

import LoadingScreen from '@/components/LoadingScreen'
import RouterLinkBack from '@/components/RouterLinkBack.vue'
import {
  flattenFields,
  traverseFieldsSync,
  traverseFields,
  getFieldPath, fieldsWithType,
} from 'paperclip-lib/src/report-configs/ReportConfigUtils'
import { fileToText } from 'paperclip-lib/src/files/FileUtils'

import DefaultReportConfig from '@/assets/defaultReportConfig.json'
import DefaultContent from '@/assets/defaultContent.json'
import ContainerTypes from '@/assets/containerTypes.json'

export default {
  name: 'ReportEditor',
  components: {
    RouterLinkBack,
    LoadingScreen,
    FormListColumnComposer,
    FormContentComposer,
    FormConfiguredContent,
    FormInput,
    FormRadio,
    GeneralModal,
    MultiFileUpload,
    PsAccordionCollapse,
    ButtonConfirmation,
    DirtyModal,
  },
  props: {
    reportConfigId: { type: String, default: null },
  },
  data () {
    return {
      formConfig: DefaultReportConfig,
      draggingElement: null,
      preparedContent: undefined,
      editContent: null,
      toolboxTab: 'form_settings',
      saving: false,
      containerTypeExcludes: {
        'auto_complete_container': ['auto_complete_container', 'repeat_container', 'condition_container'],
        'repeat_container': ['auto_complete_container', 'repeat_container', 'condition_container'],
      }
    }
  },
  computed: {
    ...mapGetters({
      findReportConfig: 'Api/ReportConfigs/find',
      resolveApiUrl: 'Api/resolveApiUrl',
    }),
    values () {
      const repeatContainers = fieldsWithType(this.formConfig.fields, 'repeat_container')

      return {
        _repeat: repeatContainers
            .map(rc => ({ [rc.id]: [0] }))
            .reduce((accu, rc) => ( { ...accu, ...rc } ), {})
      }
    },
    toolboxTabs () {
      return [
        { value: 'form_settings', label: this.$t('report_editor.tab.form_settings') },
        { value: 'create_content', label: this.$t('report_editor.tab.create_content') },
      ]
    },
    reportHandlersCount () {
      return (this.formConfig.report_handlers ?? []).length
    },
    contentKeys () {
      return flattenFields(this.formConfig.fields)
          .map(cont => {
            return cont?.options?.name
          })
          .filter(k => !!k)
    },
    inputKeys () {
      return flattenFields(this.formConfig.fields)
          .filter(con => con.type === 'input')
          .map(cont => {
            return cont?.options?.name
          })
          .filter(k => !!k)
    }
  },
  watch: {
    formConfig: {
      deep: true,
      handler (nv) {
        if (nv?.fields.length === 0) {
          this.formConfig.fields.push({ ...DefaultContent, id: v1() })
          this.saveForm()
          this.loadFormConfig()
        }
      },
    },
  },
  methods: {
    ...mapActions({
      addReportConfig: 'Api/ReportConfigs/add',
      updateReportConfig: 'Api/ReportConfigs/edit',
      getReportConfig: 'Api/ReportConfigs/view',
      addFile: 'Api/Files/add',
      deleteReportConfig: 'Api/ReportConfigs/delete',
      getFile: 'Api/Files/findOrGet',
    }),
    cloneContent (draggingContent) {
      draggingContent = _cloneDeep(draggingContent)

      traverseFieldsSync([draggingContent], field => field.id = v1())

      return draggingContent
    },
    containerAllowed (draggingContent, dragOver, parentId) {

      const parent = flattenFields(this.formConfig.fields)
            .find(field => field.id === parentId)

      const insertContainerType = this.containerTypeExcludes[parent.type]

      const draggedHasExclosedType = insertContainerType?.includes(draggingContent.type)
          || flattenFields(draggingContent.options.contents)
          .filter(field => insertContainerType?.includes(field.type))
          .length > 0

      return !draggedHasExclosedType
    },
    onDrop (draggingContent, dragOver, parentId, append = true) {
      if (parentId && !this.containerAllowed(draggingContent, dragOver, parentId)) {
        this.clearDragOptions()
        this.draggingElement = null

        const parent = flattenFields(this.formConfig.fields)
            .find(field => field.id === parentId)

        const draggingLocalized = this.$t('form_content_composer.content_type.' + draggingContent.type);
        const targetLocalized = this.$t('form_content_composer.content_type.' + parent.type);

        this.$toast.open({
          message: this.$t('report_editor.nested_auto_complete_error',{dragging: draggingLocalized, target: targetLocalized}),
          type: 'error',
          position: this.$config?.TOAST_POSITION ?? 'top',
        })

        return
      }

      if (!draggingContent.id) {
        draggingContent = this.cloneContent(draggingContent)
      }

      if (draggingContent.options.file) {
        draggingContent.options.file = {
          ...draggingContent.options.file,
          file: this.resolveApiUrl(draggingContent.options.file.file),
          thumbnail: this.resolveApiUrl(draggingContent.options.file.thumbnail),
        }
      }

      // Remove the currently dragged content, before checking for duplicate names
      let updatedFields = this.removeContent(draggingContent.id, _cloneDeep(this.formConfig.fields))
      this.$set(this.formConfig, 'fields', updatedFields)

      traverseFields([draggingContent], field => this.updateKey(field))

      updatedFields = this.insertContent(updatedFields, dragOver, draggingContent, append)
      this.$set(this.formConfig, 'fields', updatedFields)

      this.clearDragOptions()
      this.draggingElement = null
    },
    updateKey(field) {
      if (this.contentKeys.includes(field.options.name)) {
        const count = this.contentKeys.filter(key => key.startsWith(field.options.name)).length
        field.options.name = field.options.name + count

        this.$toast.open({
          message: this.$t('report_editor.double_key_count', {new: field.options.name}),
          type: 'warning',
          position: this.$config?.TOAST_POSITION ?? 'top',
        })
      }
    },
    clearDragOptions () {
      this.$nextTick().then(this.$refs.form_content.clearDragOver)
    },
    insertContent (contents, siblingContent, newContent, append) {
      const idx = contents.findIndex(c => c.id === siblingContent.id)

      if (idx === -1) {
        contents
            .filter(c => Array.isArray(c?.options?.contents))
            .forEach(c => {
              this.insertContent(c.options.contents, siblingContent, newContent, append)
            })
      } else {
        contents.splice(idx + append, 0, newContent)
      }

      return contents
    },
    removeContent (contentId, fields) {
      const contentIdx = fields.findIndex(field => field.id === contentId)

      if (contentIdx === -1) {
        fields = fields.map(field => {
          if (field?.options?.contents) {
            field.options.contents = this.removeContent(contentId, field.options.contents)

            this.addDefaultContainerContent(field)
          }

          return field
        })
      } else {
        fields.splice(contentIdx, 1)
      }

      return fields
    },
    getDefaultOptions(type) {
      const options = {}

      if (ContainerTypes.includes(type)) {
        options.contents = [
          { ...DefaultContent, id: v1() },
        ]
      }

      if (['auto_complete_container'].includes(type)) {
        options.changeValues = this.$t('form_content_composer.options_input.default_values.changeValues')
        options.changeDataSet = this.$t('form_content_composer.options_input.default_values.changeDataSet')
        options.cancelChanges = this.$t('form_content_composer.options_input.default_values.cancelChanges')
      }

      if (['repeat_container'].includes(type)) {
        options.maxRepeats = 1
      }

      if (['file'].includes(type)) {
        options.allowedMimeTypes = ['image/png', 'image/jpeg']
      }

      return options
    },
    async prepareContent (fieldDefinition) {
      if (!fieldDefinition.options) {
        fieldDefinition.options = this.getDefaultOptions(fieldDefinition.type)
      }

      if (typeof fieldDefinition.options?.name === 'string') {
        fieldDefinition.options.name = fieldDefinition.options.name.replaceAll(/[^_a-z0-9]/ig, '_')
      }

      return this.uploadImages(fieldDefinition)
    },
    async onNewContent (fieldDefinition) {
      this.preparedContent = await this.prepareContent(fieldDefinition)
    },
    async onEditContent (fieldDefinition) {
      this.editContent = await this.prepareContent(fieldDefinition)
    },
    async uploadImages (fieldDefinition) {
      if (fieldDefinition?.options?.file?.progressingFiles) {
        const file = await this.addFile({
          ...fieldDefinition.options.file.progressingFiles[0],
          $uploadProgressCallback: p => {
            fieldDefinition.options.file.onProgress(0, (p.loaded / p.total * 100))
          },
        })

        fieldDefinition.options.file.onSuccessCallback(0)
        fieldDefinition.options.file.onFinallyCallback(0)

        if (file.type === 'image/svg+xml') {
          fieldDefinition.options.svgData = await fileToText(fieldDefinition.options.file.progressingFiles[0].file)
        }

        fieldDefinition.options.file_id = file.id
        fieldDefinition.options.file = {
          ...file,
          file: this.resolveApiUrl(file.file),
          thumbnail: this.resolveApiUrl(file.thumbnail),
        }
      }

      return fieldDefinition
    },
    async uploadSuggestions (fileObj) {
      if (!fileObj || fileObj.length === 0) {
        this.$set(this.formConfig, 'suggestion_file_id', null)
        this.$set(this.formConfig, 'suggestion_file', null)
        return
      }

      const file = await this.addFile({
        ...fileObj.progressingFiles[0],
        title: 'suggestions',
        $uploadProgressCallback: p => {
          fileObj.onProgress(0, (p.loaded / p.total * 100))
        },
      })

      this.$set(this.formConfig, 'suggestion_file_id', file.id)
    },
    async onSaveContent () {
      const content = _cloneDeep(this.editContent)

      await traverseFields([content], async field => {
        if (!field?.options?.file_id) {
          return
        }

        const file = await this.getFile({ id: field.options.file_id })

        field.options.file = {
          ...file,
          file: this.resolveApiUrl(file.file),
          thumbnail: this.resolveApiUrl(file.thumbnail),
        }
      })

      this.$set(
          this.formConfig,
          'fields',
          await this.updateContent(_cloneDeep(this.formConfig.fields), content),
      )

      this.editContent = null
    },
    async updateContent (fields, content) {
      if (Array.isArray(fields)) {
        const path = getFieldPath(fields, content.id)

        _set(fields, path, content)

        return fields
      }

      return fields
    },
    addDefaultContainerContent (field) {
      if (field.options.contents.length !== 0) {
        return
      }

      if (ContainerTypes.includes(field.type)) {
        field.options.contents.push({ ...DefaultContent, id: v1() })
      }
    },
    commitDelete () {
      this.$refs.dirty.acceptNavigation()

      if (!this.reportConfigId) {
        this.$router.replace('/reports').catch(() => null)
        return
      }

      this.deleteReportConfig({
        id: this.reportConfigId,
      })
          .then(() => {
            this.$router.replace('/reports').catch(() => null)
          })
    },
    saveForm () {
      if (this.saving) {
        return
      }

      this.saving = true

      this.$refs.dirty.acceptNavigation()

      let config = _cloneDeep(this.formConfig)

      traverseFields(config.fields, field => {
        delete field?.options?.file
        delete field?.options?.svgData
        delete field?.options?.onProgress
        delete field?.options?.onSuccessCallback
        delete field?.options?.onFinallyCallback
        delete field?.options?.progressingFiles
      })

      if (this.reportConfigId) {
        this.updateReportConfig(config)
            .finally(() => {
              this.saving = false
            })
      } else {
        this.addReportConfig(config)
            .then(result => this.$router.push('/reports/config/' + result.id))
            .finally(() => {
              this.saving = false
            })
      }
    },
    addIds (fields) {
      if (!Array.isArray(fields)) {
        return fields
      }

      traverseFieldsSync(fields, field => {
        if (!field.id) {
          field.id = v1()
        }
      })

      return fields
    },
    removeState (idx) {
      const states = _cloneDeep(this.formConfig.states)
      states.splice(idx, 1)

      this.$set(this.formConfig, 'states', states)
    },
    onInput (path, value) {
      const clone = _cloneDeep(this.formConfig)
      _set(clone, path, value)

      this.formConfig = clone

      this.$forceUpdate()
    },
    getEmptyOption () {
      return { label: '', value: '', id: v1() }
    },
    async loadFormConfig () {
      if (!this.reportConfigId) {
        this.$set(this.formConfig, 'fields', this.addIds(_cloneDeep(this.formConfig.fields)))
      } else {
        this.formConfig = null

        const config = await this.getReportConfig({
          contain: ['suggestion_file', 'report_handlers'],
          id: this.reportConfigId,
        })

        this.addIds(config.fields)

        if (typeof config.suggestion_file === 'object' && config.suggestion_file !== null) {
          config.suggestion_file.file = this.resolveApiUrl(config.suggestion_file.file)
          config.suggestion_file.thumbnail = this.resolveApiUrl(config.suggestion_file.thumbnail)
        }

        await traverseFields(config.fields, async field => {
          if (!field.options?.file_id) {
            return
          }

          const file = await this.getFile({ id: field.options.file_id })

          field.options.file = {
            ...file,
            file: this.resolveApiUrl(file.file),
            thumbnail: this.resolveApiUrl(file.thumbnail),
          }

          if (['svg-radio', 'svg-checkbox'].includes(field.options.type)) {
            field.options.svgData = await (await fetch(field.options.file.file)).text()
          }
        })

        this.formConfig = config
      }
    },
  },
  created () {
    this.loadFormConfig()
  },
  mounted () {
    window.addEventListener('drop', this.clearDragOptions)
    window.addEventListener('dragend', this.clearDragOptions)
  },
  beforeDestroy () {
    window.removeEventListener('drop', this.clearDragOptions)
    window.removeEventListener('dragend', this.clearDragOptions)
  },
}
</script>
