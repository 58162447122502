<template>
  <div class="form-content-composer">
    <div :class="{'d-none': !lock}" class="content-preview">
      <p>{{ $t('form_content_composer.prepared_content_info') }}</p>

      <div class="prepared-content" style="cursor: move" draggable="true">
        <form-configured-content :contents="[value]" mode="edit" enable-drag-n-drop/>
      </div>

      <button type="button"
              class="btn btn-light btn-block mt-4"
              @click="lock= false"
      >
        {{ $t('form_content_composer.prepared_content.unlock') }}
      </button>
    </div>
    <form :class="{'d-none': lock}" @submit.prevent="prepareContent">
      <div class="mb-2">
        <form-select
            :label="$t('form_content_composer.content_type.label')"
            :placeholder="$t('form_content_composer.content_type.placeholder')"
            :options="formContentTypes"
            :value="value.type"
            required
            option-value-key="value"
            @input="onTypeInput($event)"
        />

        <form-content-composer-options-collapse
            v-if="value.type === 'collapse_container'"
            :value="value.options"
            @input="onInput('options', $event)"
        />

        <form-content-composer-options-repeat
            v-if="value.type === 'repeat_container'"
            :value="value.options"
            @input="onInput('options', $event)"
        />

        <form-content-composer-options-auto-complete
            v-if="value.type === 'auto_complete_container'"
            :value="value.options"
            @input="onInput('options', $event)"
        />

        <form-content-composer-options-condition-container
            v-else-if="value.type === 'condition_container'"
            :value="value.options"
            :content-keys="inputKeys"
            @input="onInput('options', $event)"
        />

        <form-content-composer-options-input
            v-if="value.type === 'input'"
            :value="value.options"
            :keyNotUnique="keyNotUnique"
            @input="onInput('options', $event)"
        />

        <form-content-composer-options-text
            v-else-if="value.type === 'formatString'"
            :value="value.options"
            @input="onInput('options', $event)"
            @set-sub-classes="classesFromSub = $event"
        />

        <form-content-composer-options-image
            v-else-if="value.type === 'image'"
            :value="value.options"
            @input="onInput('options', $event)"
        />

        <form-content-composer-options-canvas
            v-else-if="value.type === 'canvas'"
            :value="value.options"
            :keyNotUnique="keyNotUnique"
            @input="onInput('options', $event)"
        />
      </div>

      <ps-accordion-collapse class="bg-white mb-2">
        <template #header="{active}">
          <div class="p-2 d-flex align-items-center justify-content-between">
            {{ $t('form_content_composer.layout') }}
            <i class="fas fa-chevron-left" :class="{active}" aria-hidden="true"></i>
          </div>
        </template>
        <template #content>
          <div class="p-2">
            <form-radio
                :label="$t('form_content_composer.content_type.column')"
                class="mb-3"
                :options="columnOptions"
                v-model="column"
            />
            <form-radio
                :label="$t('form_content_composer.content_type.space_before')"
                class="mb-3"
                :options="spacesBefore"
                v-model="spaceBefore"
            />
            <form-radio
                :label="$t('form_content_composer.content_type.space_after')"
                class="mb-3"
                :options="spacesAfter"
                v-model="spaceAfter"
            />
          </div>
        </template>
      </ps-accordion-collapse>

      <hr>
      <button class="btn btn-secondary btn-block">
        {{ editMode ? $t('form_content_composer.save') : $t('form_content_composer.prepare') }}
      </button>
    </form>

  </div>
</template>

<script>
import _cloneDeep from 'lodash/cloneDeep'
import _set from 'lodash/set'

import FormRadio from '@pixelstein/ps-form/components/PsFormRadio'
import FormSelect from '@pixelstein/ps-form/components/PsFormSelect'
import FormConfiguredContent from '@pixelstein/ps-form/components/PsFormConfiguredContent'

import FormContentComposerOptionsInput from '@/components/Reports/FormContentComposerOptionsInput'
import FormContentComposerOptionsText from '@/components/Reports/FormContentComposerOptionsText'
import FormContentComposerOptionsCanvas from '@/components/Reports/FormContentComposerOptionsCanvas'
import FormContentComposerOptionsCollapse from '@/components/Reports/FormContentComposerOptionsCollapse'
import FormContentComposerOptionsImage from '@/components/Reports/FormContentComposerOptionsImage.vue'
import PsAccordionCollapse from 'pixelstein-vue-app-package/src/vue2/PsAccordion/PsAccordionCollapse.vue'
import FormContentComposerOptionsAutoComplete from '@/components/Reports/FormContentComposerOptionsAutoComplete.vue'
import FormContentComposerOptionsConditionContainer from '@/components/Reports/FormContentComposerOptionsConditionContainer.vue'
import FormContentComposerOptionsRepeat from '@/components/Reports/FormContentComposerOptionsRepeat.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'FormContentComposer',
  emits: ['input', 'save'],
  components: {
    FormContentComposerOptionsRepeat,
    FormContentComposerOptionsAutoComplete,
    FormContentComposerOptionsConditionContainer,
    FormContentComposerOptionsText,
    FormSelect,
    FormRadio,
    FormContentComposerOptionsInput,
    FormConfiguredContent,
    FormContentComposerOptionsCanvas,
    FormContentComposerOptionsCollapse,
    FormContentComposerOptionsImage,
    PsAccordionCollapse,
  },
  props: {
    value: { type: Object, default: () => ({ column: 'col-12 mt-0 mb-3', allowedMimeTypes: ['image/png', 'image/jpeg'] }) },
    editMode: { type: Boolean, default: false },
    contentKeys: { type: Array, default: () => [] },
    inputKeys: { type: Array, default: () => [] },
  },
  data () {
    return {
      lock: false,
      column: 'col-12',
      spaceBefore: 'mt-0',
      spaceAfter: 'mb-3',
      classesFromSub: '',
      initialName: null,
      initialId: null,
      keyNotUnique: false,
    }
  },
  watch: {
    column (nv, ov) {
      if (nv !== ov) {
        this.onInput('column', [nv, this.spaceBefore, this.spaceAfter, this.classesFromSub].join(' '))
      }
    },
    spaceBefore (nv, ov) {
      if (nv !== ov) {
        this.onInput('column', [this.column, nv, this.spaceAfter, this.classesFromSub].join(' '))
      }
    },
    spaceAfter (nv, ov) {
      if (nv !== ov) {
        this.onInput('column', [this.column, this.spaceBefore, nv, this.classesFromSub].join(' '))
      }
    },
    classesFromSub (nv, ov) {
      if (nv !== ov) {
        this.onInput('column', [this.column, this.spaceBefore, this.spaceAfter, nv].join(' '))
      }
    },
  },
  computed: {
    ...mapGetters({
      resolveApiUrl: 'Api/resolveApiUrl',
    }),
    formContentTypes () {
      return [
        { value: 'input', label: this.$t('form_content_composer.content_type.input') },
        { value: 'canvas', label: this.$t('form_content_composer.content_type.canvas') },
        { value: 'formatString', label: this.$t('form_content_composer.content_type.format_string') },
        { value: 'image', label: this.$t('form_content_composer.content_type.image') },
        { value: 'layout_row', label: this.$t('form_content_composer.content_type.layout_row') },
        { value: 'auto_complete_container', label: this.$t('form_content_composer.content_type.auto_complete_container'), },
        { value: 'condition_container', label: this.$t('form_content_composer.content_type.condition_container') },
        { value: 'collapse_container', label: this.$t('form_content_composer.content_type.collapse') },
        { value: 'repeat_container', label: this.$t('form_content_composer.content_type.repeat_container') },
      ]
    },
    columnOptions () {
      return [
        { value: 'col-3', label: this.$t('form_content_composer.column_options.col-3') },
        { value: 'col-4', label: this.$t('form_content_composer.column_options.col-4') },
        { value: 'col-6', label: this.$t('form_content_composer.column_options.col-6') },
        { value: 'col-8', label: this.$t('form_content_composer.column_options.col-8') },
        { value: 'col-9', label: this.$t('form_content_composer.column_options.col-9') },
        { value: 'col-12', label: this.$t('form_content_composer.column_options.col-12') },
      ]
    },
    spacesBefore () {
      return [
        { value: 'mt-0', label: this.$t('form_content_composer.column_options.space-0') },
        { value: 'mt-1', label: this.$t('form_content_composer.column_options.space-1') },
        { value: 'mt-2', label: this.$t('form_content_composer.column_options.space-2') },
        { value: 'mt-3', label: this.$t('form_content_composer.column_options.space-3') },
        { value: 'mt-4', label: this.$t('form_content_composer.column_options.space-4') },
        { value: 'mt-5', label: this.$t('form_content_composer.column_options.space-5') },
      ]
    },
    spacesAfter () {
      return [
        { value: 'mb-0', label: this.$t('form_content_composer.column_options.space-0') },
        { value: 'mb-1', label: this.$t('form_content_composer.column_options.space-1') },
        { value: 'mb-2', label: this.$t('form_content_composer.column_options.space-2') },
        { value: 'mb-3', label: this.$t('form_content_composer.column_options.space-3') },
        { value: 'mb-4', label: this.$t('form_content_composer.column_options.space-4') },
        { value: 'mb-5', label: this.$t('form_content_composer.column_options.space-5') },
      ]
    },
  },
  methods: {
    onTypeInput (type) {

      const args = {
        type,
        column: [this.column, this.spaceBefore, this.spaceAfter, this.classesFromSub].join(' '),
      }

      if (this.initialId) {
        args.id = this.initialId
      }

      this.$emit('input', args)
    },
    onInput (path, value) {
      const valueClone = _cloneDeep(this.value)

      if (
          value.name
          && this.contentKeys.filter(k => k === value.name).length > 0
          && this.value.name !== this.initialName
      ) {
        this.keyNotUnique = true
      } else {
        this.keyNotUnique = false
      }

      _set(valueClone, path, value)

      this.$emit('input', valueClone)
    },
    prepareContent () {
      if (this.editMode) {
        this.$emit('save')
      } else {
        this.lock = true
      }
    },
  },
  mounted () {
    this.initialName = this.value.name
    this.initialId = this.value.id
    const [column, spaceBefore, spaceAfter] = this.value?.column?.split(' ') ?? []

    this.column = column ?? 'col-12'
    this.spaceBefore = spaceBefore ?? 'mt-0'
    this.spaceAfter = spaceAfter ?? 'mb-3'
  },
}
</script>
